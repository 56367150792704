import React from 'react'
import logoCred from '../assets/imgs/logo.png'

const AcxHeader = () => {
  return (
    <section id="header">
      <div className="container">
        <div className="slogan">
          <img src={logoCred} alt="Logotipo da DesenvolvEV" />
          <h1>
            Solução em crédito
            <br />
            <span>
              <strong>para a sua empresa</strong>
            </span>
          </h1>
        </div>
      </div>
    </section>
  )
}

export default AcxHeader
