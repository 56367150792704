let agencias = [
    {
        id: 176,
        name: 'Sicredi centro',
    },
    {
        id: 470,
        name: 'Sicredi Rincão',
    },
]
export {agencias}
